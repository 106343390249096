import React from "react";
import ReactDOM from "react-dom/client";

import ReactGA from "react-ga4";

import App from "./App.jsx";

import "./css/index.css";

if (import.meta.env.VITE_ANALYTICS) {
	ReactGA.initialize(import.meta.env.VITE_ANALYTICS);
	ReactGA.send("pageview");
}

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
