import { forwardRef, useEffect, useRef, useState } from "react";

import { getCookie, setCookie } from "../util/cookies";

const Popup = forwardRef(function Popup({ setShowPopup }, wrapperRef) {
	const popupRef = useRef(null);
	const buttonRef = useRef(null);
	const [showMessage, setShowMessage] = useState(false);
	const [isMinor, setIsMinor] = useState(true);

	useEffect(() => {
		const ageGate = getCookie("ageGate");

		if (ageGate === "true") {
			setShowMessage(true);
			setIsMinor(false);
		}
	}, []);

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (popupRef.current && !popupRef.current.contains(e.target) && e.target.nodeName !== "BUTTON") {
				setShowPopup(false);
				document.body.style.overflow = "unset";
			}
		};

		if (!isMinor) {
			document.addEventListener("click", handleClickOutside);
		}

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [isMinor, setShowPopup]);

	const closeHandler = () => {
		setShowPopup(false);
		document.body.style.overflow = "unset";
	};

	return (
		<div
			className="fixed w-screen h-screen top-0 left-0 z-20 flex justify-center items-center transition-all backdrop-blur-md"
			ref={wrapperRef}>
			<div
				className="bg-white border-4 border-primary rounded-3xl flex justify-center items-center py-12 md:py-20 w-11/12 relative max-w-xl flex flex-col gap-5 md:text-lg text-center"
				ref={popupRef}>
				{!showMessage && (
					<>
						<p className="uppercase text-xl md:text-2xl font-bold mt-5">Sei maggiorenne?</p>
						<div className="flex gap-5">
							<button
								className="underline cursor-pointer text-xl md:text-2xl"
								ref={buttonRef}
								onClick={() => {
									setShowMessage(true);
									setIsMinor(false);
									setCookie("ageGate", "true", 30);
								}}>
								SI
							</button>
							<button
								className="underline cursor-pointer text-xl md:text-2xl"
								onClick={() => {
									setShowMessage(true);
									setIsMinor(true);
								}}>
								NO
							</button>
						</div>
					</>
				)}
				{showMessage && isMinor && (
					<>
						<p className="font-bold text-lg md:text-xl">NON HAI 18 ANNI?</p>
						<p>
							Ok, non puoi ancora entrare nel sito.
							<br /> Ma tranquillo, hai una vita di party che ti aspetta.
							<br /> E ti aspettiamo anche noi. Intanto ci vediamo fuori.
							<br /> Non è una minaccia.
						</p>
					</>
				)}
				{showMessage && !isMinor && (
					<>
						<p>scusate il sito zero sbatti</p>
						<p>ci siamo sbattuti a fare la birra</p>
						<div
							className="bg-white rounded-full border border-gray w-8 h-8 flex justify-center items-center text-lg text-gray absolute top-0 right-8 -translate-y-1/2 cursor-pointer select-none"
							onClick={closeHandler}>
							&#x2715;
						</div>
					</>
				)}
			</div>
		</div>
	);
});

export default Popup;
