const Footer = () => {
	return (
		<footer className="bg-primary pt-4 pb-8 text-center font-comic text-sm md:text-base px-10 text-black">
			sede legale, partita iva, numero di telefono e{" "}
			<a href="https://www.ceres.com/privacy" className="underline">
				privacy policy
			</a>{" "}
			sono quelli di ceres <br />
		</footer>
	);
};

export default Footer;
