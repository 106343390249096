import { useEffect, useRef, useState } from "react";

import ReactGA from "react-ga4";

import { CSSTransition } from "react-transition-group";

import Distributors from "./components/Distributors";
import Footer from "./components/Footer";
import Popup from "./components/Popup";

import blueCan from "./assets/imgs/quellablu.png";
import greenCan from "./assets/imgs/lattinascontornata.png";
import wordart from "./assets/imgs/wordart.png";
import wordartMobile from "./assets/imgs/wordart-mobile.png";
import buttonBg from "./assets/imgs/pulsante.png";

import blueNew from "./assets/imgs/new.gif";
import fireNew from "./assets/imgs/fire_new.gif";
import rainbowArrow from "./assets/imgs/rainbow_arrow.gif";
import top from "./assets/imgs/top.gif";

const App = () => {
	const [showPopup, setShowPopup] = useState(false);
	const [showDistributors, setShowDistributors] = useState(false);
	const [showTooltip1, setShowTooltip1] = useState(false);
	const [showTooltip2, setShowTooltip2] = useState(false);
	const [mouseLeft, setMouseLeft] = useState(0);
	const [mouseTop, setMouseTop] = useState(0);

	const distributorsRef = useRef(null);
	const popupRef = useRef(null);

	useEffect(() => {
		setShowPopup(true);
		document.body.style.overflow = "hidden";

		let scrollTop = 0;

		const mouseMoveListener = (e) => {
			scrollTop =
				window.scrollY !== undefined
					? window.scrollY
					: (document.documentElement || document.body.parentNode || document.body).scrollTop;

			setMouseLeft(e.clientX);
			setMouseTop(e.clientY + scrollTop);
		};

		const scrollListener = (e) => {
			const newScrollTop =
				window.scrollY !== undefined
					? window.scrollY
					: (document.documentElement || document.body.parentNode || document.body).scrollTop;

			if (scrollTop !== newScrollTop) {
				const diff = newScrollTop - scrollTop;
				scrollTop = newScrollTop;
				setMouseTop((prev) => prev + diff);
			}
		};

		window.addEventListener("mousemove", mouseMoveListener);
		window.addEventListener("scroll", scrollListener);

		return () => {
			window.removeEventListener("mousemove", mouseMoveListener);
			window.removeEventListener("scroll", scrollListener);
		};
	}, []);

	const youtubeClickHandler = () => {
		ReactGA.event({
			category: "Click",
			action: "youtube_click",
		});
	};

	const instagramClickHandler = () => {
		ReactGA.event({
			category: "Click",
			action: "instagram_click",
		});
	};

	return (
		<>
			<main className={`flex flex-col justify-start ml-5 md:ml-8 lg:ml-14 grow text-black`}>
				<div className="w-11/12 max-w-7xl py-12 md:py-20">
					<h1 className="text-4xl md:text-5xl lg:text-6xl font-serif font-bold text-black inline-block md:px-16 select-none">
						<mark className="bg-primary">
							il sito di <span className="text-5xl md:text-7xl lg:text-8xl">TOP</span>
						</mark>
					</h1>
					<img
						src={top}
						alt=""
						className="absolute left-[70%] top-[5%] w-[18%] md:w-[13%] h-auto z-10 pointer-events-none select-none"
					/>
					<div className="grid md:grid-cols-2 gap-6 md:gap-12 my-12 md:my-20 relative select-none px-6 md:px-0">
						<img
							src={greenCan}
							alt="TOP Pilsner"
							className="w-full h-auto"
							onMouseEnter={() => {
								setShowTooltip1(true);
							}}
							onMouseLeave={() => {
								setShowTooltip1(false);
							}}
						/>
						<img
							src={blueNew}
							alt=""
							className="absolute w-[40%] md:w-[25%] left-[50%] md:left-[24%] top-[5%] md:top-[10%] pointer-events-none"
						/>
						<img
							src={blueCan}
							alt="TOP Royal"
							className="w-full h-auto"
							onMouseEnter={() => {
								setShowTooltip2(true);
							}}
							onMouseLeave={() => {
								setShowTooltip2(false);
							}}
						/>
						<img
							src={fireNew}
							alt=""
							className="absolute w-[60%] md:w-[33%] left-0 md:left-[initial] md:right-0 bottom-0 translate-y-1/2 -translate-x-[15%] md:translate-x-1/4 pointer-events-none"
						/>
					</div>
					<img
						src={wordart}
						alt="Due birre che sanno di birra"
						className="select-none pointer-events-none h-12 lg:h-16 w-auto mb-8 md:px-16 hidden md:block"
					/>
					<img
						src={wordartMobile}
						alt="Due birre che sanno di birra"
						className="select-none pointer-events-none h-20 sm:h-28 w-auto mb-8 md:px-16 block md:hidden"
					/>
					<p className="md:text-lg md:px-16">
						guarda il video qui{" "}
						<a
							href="https://www.instagram.com/reel/C8WlKctq5gf"
							className="font-bold underline text-blue"
							target="_blank"
							rel="noreferrer"
							onClick={youtubeClickHandler}>
							https://www.instagram.com/reel/C8WlKctq5gf
						</a>{" "}
						<br />
						seguici sull’instagram di ceres{" "}
						<a
							href="https://www.instagram.com/ceresofficial/"
							className="font-bold underline text-blue"
							target="_blank"
							rel="noreferrer"
							onClick={instagramClickHandler}>
							https://www.instagram.com/ceresofficial/
						</a>
					</p>
					<div className="flex justify-center items-center pt-12 md:pt-20 gap-1 md:gap-4 select-none">
						<img src={rainbowArrow} alt="" className="h-6 md:h-10 lg:h-16 w-auto pointer-events-none select-none" />
						<div
							className="uppercase font-serif shrink-0 bg-[length:100%_100%] bg-no-repeat bg-center text-2xl md:text-3xl lg:text-4xl aspect-[610/81] pl-6 pr-12 flex items-center cursor-pointer"
							style={{ backgroundImage: `url(${buttonBg})` }}
							onClick={() => {
								setShowDistributors((prev) => !prev);
							}}>
							Comprale qui
						</div>
						<img
							src={rainbowArrow}
							alt=""
							className="h-6 md:h-10 lg:h-16 w-auto rotate-180 pointer-events-none select-none"
						/>
					</div>
					<CSSTransition nodeRef={distributorsRef} classNames="slide" in={showDistributors} timeout={300}>
						<Distributors ref={distributorsRef} />
					</CSSTransition>
				</div>
			</main>
			{showTooltip1 && (
				<span
					className="absolute font-comic bg-white text-sm pointer-events-none select-none hidden md:block"
					style={{ left: mouseLeft + 10 + "px", top: mouseTop + 10 + "px" }}>
					lattinascontornata.png
				</span>
			)}
			{showTooltip2 && (
				<span
					className="absolute font-comic bg-white text-sm pointer-events-none select-none hidden md:block"
					style={{ left: mouseLeft + 10 + "px", top: mouseTop + 10 + "px" }}>
					quellablu.png
				</span>
			)}
			<Footer />
			<CSSTransition nodeRef={popupRef} classNames="fade" in={showPopup} timeout={300} unmountOnExit mountOnEnter>
				<Popup ref={popupRef} setShowPopup={setShowPopup} />
			</CSSTransition>
		</>
	);
};

export default App;
