import { forwardRef } from "react";

import distributors from "../assets/imgs/distributors.jpg";
import distributorsMobile from "../assets/imgs/distributors_mobile.jpg";

const Distributors = forwardRef(function Distributors(props, ref) {
	return (
		<div className="w-full overflow-hidden max-h-0" ref={ref}>
			<img src={distributors} alt="" className="w-10/12 max-w-5xl h-auto hidden md:block" />
			<img src={distributorsMobile} alt="" className="w-full h-auto md:hidden" />
		</div>
	);
});

export default Distributors;
